import React from "react";
import { useHistory } from "react-router-dom";
import Header2 from "../../components/Header2";
import Footer from "../../components/Footer";
import ContactSection from "./Sections/Contact";
import VendorAboutSection from "./Sections/VendorAboutSection";
import VenueCTA from "../../assets/VenueCTA.png";
import ReadSpeaker from "../../components/ReadSpeaker";

// import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
// import { useMount, useSetState } from "react-use";
// import { useAppContext } from '../../context';

export const Landing = () => {
	const history = useHistory();
	// const {
	// setState,
	// state: { run, tourActive },
	// } = useAppContext();
	// const handleClickStart = () => {
	// setState({ run: true, tourActive: true });
	// };
	return (
		<>
			<div className="div-cover">
				<Header2 />
				<div className="div-content">
					<div className="div-para">
						<h1 className="span-heading">
							Enable Hospitality for All
						</h1>
						<h2 className="span-subheading">
							QR codes are not accessible!
						</h2>
						{/* <button
							type="button"
							onClick={() => {
								throw new Error("Sentry Test Error");
							}}
						>
							Break the world
						</button> */}

						{/* <button bg="white" onClick={handleClickStart} size="lg">
							Start
						</button> */}
						<ReadSpeaker />

						<div className="div-18">
							<div className="flex-container">
								<div className="column-2 text-column">
									<div className="div-20">
										<div
											className="span-montserrat"
											style={{
												fontSize: "26px",
													}}
										>
											<h3
												className="header-content"
												style={{ textAlign: "left", fontSize: "22px" }}
											>Make your menu accessible</h3>

											<div
												className="header-content"
												style={{ textAlign: "left", fontSize: "22px" }}
											>
                        
												- Enable the blind to read your menu</div>
											<div
												className="header-content"
												style={{ textAlign: "center", fontSize: "22px" }}
											>

												- Serve all customers
											</div>
											<div
												className="header-content"
												style={{ textAlign: "left", fontSize: "22px" }}
											>
												- Increase your market
											</div>
											<div
												className="header-content"
												style={{ textAlign: "left", fontSize: "22px" }}
											>
												- Tap into 16% of Australia’s population
											</div>
											<div
												className="header-content"
												style={{ textAlign: "left", fontSize: "22px" }}
											>
												- 4.4 Million people currently underserved in AUS alone!
											</div>
											<div
												className="header-content"
												style={{ textAlign: "left", fontSize: "22px" }}
											>
												- Show that you support accessibility and inclusion for
												everyone!
											</div>
										</div>
									</div>

									<div>
										<div className="marginal-top-span pointer">
											<img
												id="venue-sign-up-button"
												className="venue-sign-up-btn"
												onClick={() => history.push("/SignUp")}
												loading="lazy"
												src={VenueCTA}
											/>
										</div>
									</div>
								</div>
								<div className="column-2">
									<div className="video-container" style={{marginTop: '-20px'}}>
										<iframe
											width="100%"
											height="500"
											src="https://www.youtube.com/embed/fj-GOVZtgKo?rel=0"
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
										{/* <ReadSpeaker></ReadSpeaker> */}
									</div>
								</div>
							</div>
							<h3 className="marginal-top-span">
								When accessibility is not an option <br /> Piatto is the solution!
							</h3>
						</div>
					</div>
					<VendorAboutSection />

					<ContactSection />

					<Footer />
				</div>
			</div>
			<style jsx>
				{`
			.div {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			.div-cover {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.flex-container {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			width: 100%;
			}
      .text-column {
      display: felx;
      flex-direction: column;
      height: 360px;
      }

			.header-content {
				display: flex;
				justify-content: flex-start;
				fontSize: 22px;
				textAlign: left;
			}

			@media (max-width: 991px) {
				.div-cover {
					max-width: 100%;
				}
			}
			.div-content {
				display: flex;
				width: 100%;
				flex-direction: column;
				align-items: start;

			}
			@media (max-width: 991px) {
				.div-content {
					max-width: 100%;
				}
			}


			.span-heading {
				color: #fff;
				margin: 50px 0 0 0;
				font: 500 55px Inclusive Sans, sans-serif;
				background: linear-gradient(
					90deg,
					#9b5de5,
					#f15bb5,
					#fee440,
					#00bbf9,
					#00f5d4
				);

				-webkit-background-clip: text;
				background-clip: text;
				color: transparent;
				animation: knockOut 2s linear infinite;
			}
				.span-subheading {
				color: #fff;
				font: 400 30px Inclusive Sans, sans-serif;
				margin-top: 10px;
				}
			@keyframes knockOut {
				0% {
					background-position: 0px 0px;
				}

				100% {
					background-position: 1000px 0px;
				}
			}
			@media (prefers-reduced-motion: reduce) {
				.span-heading {
					animation: knockOut 8s linear infinite both;
					background-color: green;
					text-decoration: overline;
				}
			}
			@media (max-width: 991px) {
				.span-heading {
					max-width: 100%;
					margin-top: 80px;
					font-size: 40px;
					animation: knockOut 4s linear infinite;
				}
			}
			.div-para {
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: 18px;
				color: #fff;
				font-weight: 400;
				text-align: center;
				padding: 4px 60px; 
				background-color : #000;
				padding: 5%;
				width: 90%;

			}
			.venue-sign-up-btn {
				margin-left:180px;
			}
			@media (max-width: 991px) {
				.video-container {
    
					width:90% !important;
				}
				.venue-sign-up-btn {
					margin-left: 0;
				}
			}
			.span-montserrat {
				font-family: Inclusive Sans, sans-serif;
			}
			.marginal-top-span {
				font-family: Inclusive Sans, sans-serif;
				align-self: stretch;
				margin-top: 25px;
				font-size: 26px;
			}
			@media (max-width: 991px) {
				.marginal-top-span {
					max-width: 100%;
					font-size: 26px;
				}
			}
			.green-click-button {
				font-family: Inclusive Sans, sans-serif;
				justify-content: center;
				border-radius: 40px;
				box-shadow: 0px 0px 25px 0px rgba(168, 255, 53, 0.25);
				background-color: #a8ff35;
				margin-top: 30px;
				color: #000;
				font-weight: 700;
				white-space: nowrap;
				padding: 20px 21px;
				align-self: center;
				width: 180px;
			}
			@media (max-width: 991px) {
				.green-click-button {
					white-space: initial;
					margin-top: 40px;
					padding: 20 20px;
				}
			}
			.pointer { 
				cursor: pointer;
			}
			#header-div { display: none !important; } 

			input, input:focus-visible{
				border: none; !important
				height : 95%;
				width : 95%;
				outline: none; !important;
				font-size: 20px;
			}
			img{
				aspect-ratio : auto !important;
			}
			.video-container {
				margin-top: 30px;
				width: 75%;
				max-width: 560px; /* Limiting width for responsiveness */
				height: 360px; /* Fixed height for the video */
				background-color: #000; /* Black background */
				overflow: hidden;
				diplay: flex;
				justify-content: center;
				align-items: center;
			}

			.video-container iframe {
				width: 100%;
				height: 100%;
			}
			.column-11 {
				display: flex;
				flex-direction: column;
				line-height: normal;
				width: 50%;
				margin-left: 20px;
			}

		`}{" "}
			</style>
		</>
	);
};
export default Landing;
